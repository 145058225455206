<template>
  <el-main>
    <el-button style="margin-bottom: 15px" type="primary" class="el-icon-plus" size="small" @click="add">添加员工</el-button>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="员工姓名：">
        <el-input size="small" v-model="searchForm.staff_name"></el-input>
      </el-form-item>
      <el-form-item label="员工电话：">
        <el-input size="small" v-model="searchForm.staff_mobile"></el-input>
      </el-form-item>
      <el-form-item label="公司名称：">
        <el-input size="small" v-model="searchForm.company_name"></el-input>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="staff_name" label="员工姓名" align="center"></el-table-column>
      <el-table-column prop="staff_mobile" label="员工电话" align="center"></el-table-column>
      <el-table-column prop="company_name" label="公司名称" align="center"></el-table-column>
      <el-table-column prop="create_time" label="申请时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="edit(row)" type="text">编辑</el-button>
          <el-button @click="del(row)" type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="添加" :visible.sync="showAdd" width="650px">
      <el-form ref="form" :model="addForm" :rules="rules" label-width="100px">
        <el-form-item label="员工姓名：" prop="staff_name">
          <el-input v-model="addForm.staff_name"></el-input>
        </el-form-item>
        <el-form-item label="员工电话：" prop="staff_mobile">
          <el-input v-model="addForm.staff_mobile"></el-input>
        </el-form-item>
        <el-form-item label="公司名称：" prop="company_name">
          <el-input v-model="addForm.company_name"></el-input>
        </el-form-item>
        <el-form-item label="登录密码：" v-if="addForm.id">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="登录密码：" prop="password" v-else>
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAdd">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        staff_name: '',
        staff_mobile: '',
        company_name: '',
        start_time: '',
        end_time: '',
      },
      addForm: {
        staff_name: '',
        staff_mobile: '',
        password: '',
        company_name: '',
      },
      rules: {
        staff_name: { required: true, message: '请填写员工姓名', trigger: 'blur' },
        staff_mobile: { required: true, message: '请填写员工电话', trigger: 'blur' },
        password: { required: true, message: '请填写登录密码', trigger: 'blur' },
        company_name: { required: true, message: '请填写公司名称', trigger: 'blur' },
      },
      list: [],
      total_number: 0,
      showAdd: !1,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        staff_name: '',
        staff_mobile: '',
        company_name: '',
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.serviceProvider.staffList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    edit(row) {
      this.addForm.id = row.id;
      this.addForm.staff_name = row.staff_name;
      this.addForm.staff_mobile = row.staff_mobile;
      this.addForm.password = row.password;
      this.addForm.company_name = row.company_name;
      this.showAdd = !0;
      this.$refs.form.resetFields();
    },
    add() {
      this.addForm = {
        staff_name: '',
        staff_mobile: '',
        password: '',
        company_name: '',
      };
      this.showAdd = !0;
    },
    comfirmAdd() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.addForm.id ? this.$api.serviceProvider.editStaff : this.$api.serviceProvider.addStaff, this.addForm).then(res => {
            if (res.code == 0) {
              this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
              this.getList();
              this.showAdd = !1;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>